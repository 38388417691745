import React, { useEffect, useMemo, useRef, useState } from 'react';
import { KkmInfo } from '@/components/Kkm/KkmInfo';
import { PageTitle } from '@/shared/ui/PageTitle';
import { BreadCrumb, BreadCrumbItem } from '@/shared/ui/BreadCumb';
import { Button } from '@/shared/ui/Buttons';
import { Card } from '@/shared/ui/Card';
import classNames from 'classnames';
import { Link, Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Hr } from '@/shared/ui/Hr';
import { Input } from '@/shared/ui/Inputs';
import { DocumentIcon } from '@/assets/icons/DocumentIcon';
import { ClockIcon } from '@/assets/icons/ClockIcon';
import { Checkbox } from '@/shared/ui/Checkbox';
import { UnlockIcon } from '@/assets/icons/UnlockIcon';
import { Select } from '@/shared/ui/Select';
import {
	createColumnHelper,
	getCoreRowModel,
	getSortedRowModel,
	SortingState,
	useReactTable,
} from '@tanstack/react-table';
import { TableView } from '@/shared/ui/Tables';
import Styles from './KkmDetailsPage.module.scss';
import Scrollbars from 'react-custom-scrollbars-2';
import { DateFilter } from '@/components/DateFilter';
import { Dayjs } from 'dayjs';
import { useSelect } from '@/shared/hooks';
import { KkmStore } from '@/entities/Kkm';
import { TaxpayersStore } from '@/entities/Taxpayer';
import { observer } from 'mobx-react-lite';
import { Preload } from '@/shared/ui/Preload';
import { formatDate, showToast } from '@/shared/lib';
import { getSnapshot } from 'mobx-state-tree';
import { toast, ToastContainer } from 'react-toastify';
import { BlockIcon } from '@/assets/icons/BlockIcon';
import { KkmStateModal } from '@/modals/Kkm/KkmStateModal';
import { ConfirmModal } from '@/modals/General';
import { billingStatus } from '@/shared/static/kkm';
import { Paginate } from '@/shared/ui/Paginate';
import { TradePointStore } from '@/store/TradePointsStore';
import { formatDateUnix } from '@/shared/lib/formatDate';
import { api } from '@/shared/api/api';
import axios from 'axios';
import { IErrorMessage } from '@/shared/api/instanceAxios';
import { kkmServices } from '@/shared/api/kkmServices';


interface ICheck {
	transactionId: string;
	type: string;
	fiscalId: number | string;
	offlineFiscalId: number;
	transactionDate: string;
	sum: number;
	ibdStatus: string;
	ibdSendDate: string;
}

const checkColumnHelper = createColumnHelper<ICheck>();
const typeDevices = [
	'Стационарное устройство',
	'Мобильное устройство',
	'ККТ входит в состав платежного терминала (банкомата)',
];
export const KkmDetailsPage = observer(() => {
		const [searchParams, setSearchParams] = useSearchParams();
		const size = searchParams.get('size');
		const page = searchParams.get('page');
		const [reason, setReason] = useState('');
		const navigate = useNavigate();
		const { id } = useParams();
		const Kkm = KkmStore.currentKkm;
		const Taxpayer = TaxpayersStore.currentTaxpayer;
		const [sorting, setSorting] = useState<SortingState>([]);
		const modelRef = useRef<HTMLInputElement>(null);
		const registerNumberRef = useRef<HTMLInputElement | null>(null);
		const serialNumRef = useRef<HTMLInputElement | null>(null);
		const yearIssueRef = useRef<HTMLInputElement | null>(null);
		const maskValidation = useRef<HTMLInputElement | null>(null);
		const [stateModal, setStateModal] = useState(false);
		const [confirmBlocKkm, setConfirmBlockKkm] = useState(false);
		const [confirmBlockTraffic, setBlockConfirmTraffic] = useState(false);
		const [confirmChangeMaskValidation, setConfirmMaskValidation] = useState(false);

		const { options, getLabelOption, handleChangeOption, setOptions } = useSelect([]);
		useEffect(() => {
			kkmServices.getRegistrationStatus().then(response => {
				setOptions(response.map((item: any) => ({
					id: item.id,
					label: `${item.nameRu} (${item.id}) ${item.code}`,
					checked: false,
				})).sort((a: number, b: number) => a.id - b.id));
			});
		}, []);


		useEffect(() => {
			KkmStore.fetchById(id as string).then((kkm) => {
				handleChangeOption(kkm.registrationStatus);
				TaxpayersStore.fetchById(kkm.organisationId);
				TradePointStore.fetchTradePointById(kkm.retailPlaceId);
			});

		}, [id, handleChangeOption]);

		useEffect(() => {
			setReason(Kkm?.blockReason || '');
		}, [Kkm]);

		useEffect(() => {
			KkmStore.fetchState(Number(id)).then(response => {
				if (maskValidation.current) {
					maskValidation.current.value = KkmStore.state.validationMask;
				}
			});
		}, [id]);
		useEffect(() => {
			return () => {
				TaxpayersStore.reset();
				KkmStore.clear();
			};
		}, []);
		const block = async () => {
			setConfirmBlockKkm(false);
			await showToast(async () => {
				const response = await KkmStore.blockUnblock(reason, +(id as string), Boolean(Kkm?.blockDate));
				await KkmStore.fetchById(id as string);
				await TaxpayersStore.fetchById(Kkm?.organisationId);
				return response;
			});
		};
		const onPaginate = (event: React.ChangeEvent<unknown>, page: Number) => {
			setSearchParams(param => {
				param.set('page', page.toString());
				return param;
			});
			fetchData(page, size);
		};

		const onChangeSelectSize = (size: number) => {
			setSearchParams(param => {
				param.set('size', size.toString());
				return param;
			});
			fetchData(page, size);
		};

		const blockTraffic = async () => {
			setBlockConfirmTraffic(false);
			await showToast(async () => {
				const response = await KkmStore.blockUnblockTraffic(Number(id as string), Kkm?.isTrafficBlocked);
				await KkmStore.fetchById(id as string);
				await TaxpayersStore.fetchById(Kkm?.organisationId);
				return response;
			});
		};

		useEffect(() => {
			if (modelRef.current && serialNumRef.current && yearIssueRef.current && registerNumberRef.current) {
				modelRef.current.value = Kkm?.model || '';
				serialNumRef.current.value = Kkm?.serialNum || '';
				yearIssueRef.current.value = Kkm?.manufactureYear || '';
				registerNumberRef.current.value = Kkm?.kgdId || '';
			}
		}, [Kkm, modelRef, registerNumberRef, serialNumRef, yearIssueRef]);
		const [datePickerValue] = useState<Dayjs | null>(null);
		const changeStatus = async (statusId: number) => {
			await showToast(async () => {
				return await KkmStore?.changeRegistrationStatus(Number(id as string), statusId);
			});
		};

		const dataTaxpayer = [
			{
				key: 'ИИН/БИН',
				value: Taxpayer?.iinBin || '',
				isFirst: true,
			},
			{
				key: 'Свидетельство о гос. регистрации',
				value: '№1234567',
			},
			{
				key: 'ОКЭД',
				value: Taxpayer?.oked || '',
			},
			{
				key: 'Ответственное лицо',
				value: `${Taxpayer?.responsiblePerson?.secondName || ''} ${Taxpayer?.responsiblePerson?.firstName[0] || ''}.${Taxpayer?.responsiblePerson?.lastName[0]}.`,

			},
			{
				key: 'Телефон',
				value: Taxpayer?.phone || '',
			},
		];
		const dataKkm = [
			{
				key: 'ID ККМ',
				value: Kkm?.id || '',
			},
			{
				key: 'Статус ККМ',
				value: `${options.find(item => item.id === Kkm?.registrationStatus)?.label || ''} ${formatDate(Kkm?.registrationStatusChangeDate)}`,
			},
			{
				key: 'Тип устройства',
				value: typeDevices[Kkm?.type],
			},
		];


		const columnsCheck = useMemo(() => [
			checkColumnHelper.accessor('transactionId', {
				header: 'Транзакция',
				cell: ({ getValue, row }) => <Link className='table-link'
																					 to={`/kkm/${id}/transactionInfo/${getValue()}/${row.original.transactionDate}`}>{getValue()}</Link>,
			}),
			checkColumnHelper.accessor('type', {
				header: 'Операция',
			}),
			checkColumnHelper.accessor('fiscalId', {
				header: 'Номер',
				cell: ({ getValue, row }) => (getValue() && getValue() !== 'null') ?
					<Link className='table-link'
								to={`/kkm/${id}/check/${getValue()}/${row.original.transactionDate}/${row.original.transactionId}`}>{getValue()}</Link> : '',
			}),
			checkColumnHelper.accessor('offlineFiscalId', {
				header: 'Аварийный номер',
				cell: ({ getValue }) => getValue() ? getValue() : '-',
			}),
			checkColumnHelper.accessor('transactionDate', {
				header: 'Дата',
				cell: ({ getValue }) => formatDate(getValue()),
				sortingFn: (a, b) => formatDateUnix(b.original.transactionDate) - formatDateUnix(a.original.transactionDate),
			}),
			checkColumnHelper.accessor('sum', {
				header: 'Сумма',
				cell: ({ getValue }) => getValue() ? getValue() : '-',
			}),
			checkColumnHelper.accessor('ibdStatus', {
				header: 'Статус',
				cell: ({ getValue }) => getValue() ? getValue() : '',
			}),
			checkColumnHelper.accessor('ibdSendDate', {
				header: 'Взаимодействие с IOFD',
				cell: ({ getValue }) => getValue() ? formatDate(getValue()) : '',
			}),
		], [id]);


		const checksTable = useReactTable({
			data: getSnapshot(KkmStore.operationList.list),
			columns: columnsCheck,
			state: {
				sorting,
			},
			onSortingChange: setSorting,
			getCoreRowModel: getCoreRowModel(),
			getSortedRowModel: getSortedRowModel(),
		});

		const dataLocation = [
			{
				key: 'Наименование места установки',
				value: TradePointStore.currentRetailPlace?.title,
			},
			{
				key: 'Адрес',
				value: Kkm?.paymentAddress?.fullAddress + ` ${Kkm?.paymentAddress?.housing || ''} ${Kkm?.paymentAddress?.house || ''} ${Kkm?.paymentAddress?.apartment ? `, ${Kkm?.paymentAddress?.apartment}` : ''}`,
			},
			{
				key: 'Статус в биллинге',
				value: billingStatus.find(item => item.id === Kkm?.billingStatus)?.label || '',
			},
		];
		const location = useLocation();


		const [shiftNumber, setShiftNumber] = useState<number | undefined>(undefined);

		const incrementShiftNumber = (): void => {
			if (shiftNumber || shiftNumber === 0) setShiftNumber(shiftNumber + 1);
		};

		const decrementShiftNumber = (): void => {
			if (shiftNumber || shiftNumber === 0) setShiftNumber(shiftNumber - 1);
		};
		const fetchData = (page?: any, size?: any) => {
			KkmStore.fetchOperationListById(Number(id), Number(shiftNumber), { page, size });
		};
		const changeKkm = async () => {
			await showToast(async () => {
				const kkmId = +(id as string);
				const model = modelRef.current?.value as string;
				const serial = serialNumRef.current?.value as string;
				const regNum = registerNumberRef?.current?.value as string;
				const year = +(yearIssueRef.current?.value as string);
				return await KkmStore.kkmChange(kkmId, regNum, year, serial, model);
			});
		};
		const setMaskValidation = async () => {
			const value = maskValidation.current?.value;
			if (value) {
				await showToast(async () => {
					return await api.setValidationMask(+(id as string), +value);
				});
			}
			setConfirmMaskValidation(false);
		};
		return (
			<>
				<Card className='mb-12'>
					<div className={classNames('py-30 pl-27 pr-26')}>
						<BreadCrumb>
							<BreadCrumbItem to='/taxpayers'>Налогоплательщики</BreadCrumbItem>
							<BreadCrumbItem to={`/taxpayers/${Taxpayer?.id}`}>{Taxpayer?.title}</BreadCrumbItem>
							<BreadCrumbItem active to={location.pathname}>ККМ</BreadCrumbItem>
						</BreadCrumb>
						<PageTitle className={classNames('mb-24')}>
							{Taxpayer.title}
						</PageTitle>
						<KkmInfo data={dataTaxpayer} />
						<Hr className={classNames('mt-33 mb-33')} />
						<PageTitle className={classNames('mb-30')}>
							Название ККМ
						</PageTitle>
						<KkmInfo data={dataKkm} />
						<div className='flex mt-28 mb-25 a-end'>
							<div className='item mr-80 grow'>
								<div className='label mb-10'>
									Регистрационный номер:
								</div>
								<Input ref={registerNumberRef} />
							</div>
							<div className='item mr-80 grow'>
								<div className='label mb-10'>
									Серийный номер:
								</div>
								<Input ref={serialNumRef} />
							</div>
							<div className='item grow'>
								<div className='label mb-10'>
									Год выпуска:
								</div>
								<Input ref={yearIssueRef} />
							</div>
						</div>
						<div className='flex a-end mb-35'>
							<div className='item mr-20 grow'>
								<div className='label mb-9'>
									Модель:
								</div>
								<Input ref={modelRef} className='grow' />
							</div>
							<div className='item'>
								<Button onClick={() => changeKkm()} className={classNames(Styles.button)}>Сохранить
									изменения</Button>
							</div>
						</div>
						<KkmInfo data={dataLocation} />
						<div className='flex mt-28 j-between a-end mb-31'>
							<div className='item'>
								<div className='label mb-9'>
									Изменить статус регистрации на:
								</div>
								<Select clickOutside closeOnSelect={false} label={getLabelOption()}
												className={Styles.select} contentClassName={Styles.selectContent}>
									{
										options.map(item =>
											<li key={item.id} className={classNames('option', item.checked && 'active')}>
												<label>
													<Checkbox
														onChange={() => {
															changeStatus(item.id);
															handleChangeOption(item.id);
														}}
														className='mr-10'
														checked={item.checked}
													/>
													{item.label}
												</label>
											</li>,
										)
									}

								</Select>
							</div>
							<div className='item flex j-end grow'>
								{/*<Button isIcon className='mr-10' tip='Документы'*/}
								{/*				onClick={() => navigate(`/kkm/${id}/check/1`)}>*/}
								{/*	<DocumentIcon />*/}
								{/*</Button>*/}
								<Button tip='История изменений' isIcon onClick={() => navigate(`/kkm/${id}/history`)}>
									<ClockIcon />
								</Button>
							</div>
						</div>
						<Hr />
						<div className='flex mt-30 mb-30'>
							<div className='item mr-80 grow'>
								<div className='label mb-10'>
									Блокировка ККМ
								</div>
								<Input placeholder='Опишите причину блокировки' className='grow' value={reason}
											 onChange={(e) => setReason(e.target.value)} />
								<div className='item flex mt-15 a-center'>
									<Button onClick={() => setConfirmBlockKkm(true)}
													className={classNames(Styles.button, 'mr-20')}>
										{Kkm?.blockDate ? 'Разблокировать' : 'Заблокировать'}
									</Button>
									<div className='item flex a-center grow'>
										<div className='icon flex a-center j-center mr-6 '>

											{Kkm?.blockDate ? <BlockIcon /> : <UnlockIcon />}
										</div>
										<span
											className={classNames('fw-500', !Kkm?.blockDate ? 'c_done_color' : 'c_danger_color')}>
                                            {
																							Kkm?.blockDate ? 'Заблокирована' : 'Не заблокирована'
																						}
                                    </span>
									</div>
								</div>
							</div>
							<div className='item grow'>
								<div className='label mb-10'>
									Маска валидации:
								</div>
								<Input onChange={e => e.target.value = e.target.value.replace(/\D/g, '')} isSearch type='text'
											 ref={maskValidation} placeholder='Маска валидации:'
											 className='grow' />
								<div className='item flex mt-15'>
									<Button onClick={() => {
										console.log(maskValidation.current?.value);
										maskValidation.current?.value && setConfirmMaskValidation(true);
									}}
													className={classNames(Styles.button)}>
										Установить
									</Button>
								</div>
							</div>
						</div>
						<Hr />
						<h3 className='mb-22 fs-18 fw-500 c_first_color mt-30'>Перенос в стеджинг</h3>
						<div className='flex a-end mb-30'>
							<div className='item grow mr-20'>
								<div className='label mb-10'>Номер смены:</div>
								<Input placeholder='Введите номер смены' className={Styles.shiftNumber} />
							</div>
							<div className='item grow mr-20'>
								<div className='label mb-10'>Дата транзакции для переноса:</div>
								<DateFilter value={datePickerValue} setValue={() => console.log()} />
							</div>
							<div className='item grow mr-20'>
								<Button className={classNames(Styles.button)}>
									Копировать
								</Button>
							</div>
							<div className='item grow mr-20 pb-10'>
								<Link to='/' className='table-link fw-500 fs-14'>Просмотр перемещенных данных</Link>
							</div>
						</div>
						<Hr />
						<div className='item flex py-30 a-center'>
							<Button style={{ width: 194 }} className='mr-20' onClick={() => setBlockConfirmTraffic(true)}>
								{Kkm?.isTrafficBlocked ? 'Разблокировать' : 'Заблокировать'} трафик
							</Button>
							<div className='item flex a-center grow'>
								<div className='icon flex a-center j-center mr-6 '>
									{!Kkm?.isTrafficBlocked ?
										<UnlockIcon />
										:
										<BlockIcon />
									}
								</div>
								<span
									className={classNames('fw-500', Kkm?.isTrafficBlocked ? 'c_danger_color' : 'c_done_color')}>
                               {
																 Kkm?.isTrafficBlocked ? 'Трафик заблокирован' : 'Трафик не заблокирован'
															 }
                            </span>
							</div>
						</div>
						<Hr />
						<h3 className='py-30 fs-18 fw-500 c_first_color'>Смены</h3>
						<div className='flex a-end mb-20'>
							<div className='item  mr-20'>
								<Button className={classNames(Styles.button)} onClick={() => navigate('shiftList')}>
									Посмотреть список отчетов
								</Button>
							</div>
							<div className='item grow mr-20 pb-10'>
								<Link to={`/kkm/${id}/lastShift`} className='table-link fw-500 fs-14'>Последняя смена</Link>
							</div>
						</div>
						<Hr />
						<h3 className='py-30 fs-18 fw-500 c_first_color'>Состояние</h3>
						<div className='flex a-end'>
							<div className='item  mr-20'>
								<Button className={classNames(Styles.button)} onClick={() => setStateModal(true)}>
									Посмотреть состояние
								</Button>
							</div>
						</div>
					</div>
				</Card>
				<Card>
					<div className='pt-30 px-27'>
						<PageTitle className='mb-20'>
							Список чеков
						</PageTitle>
						<div className='flex'>
							<div className='item mr-24'>
								<Input
									onChange={(e) => setShiftNumber(+e.target.value)}
									value={shiftNumber}
									increment={incrementShiftNumber}
									decrement={decrementShiftNumber}
									type='number'
									className={Styles.shiftNumber}
									placeholder='Укажите номер смены'
								/>
							</div>
							<Button className={classNames(Styles.button)}
											onClick={() => fetchData(page, size)}>
								Показать
							</Button>
						</div>
					</div>
					<Scrollbars autoHeightMax={'100%'} autoHeight>
						<TableView className={classNames(Styles.checkTable, 'table-left')}
											 tableInstance={{ ...checksTable }} />
					</Scrollbars>
				</Card>
				{
					KkmStore.operationList.list.length ?
						<Paginate onChange={onPaginate} currentPage={KkmStore.currentPage}
											countPages={KkmStore.totalPages}
											size={size} onChangeSelectSize={onChangeSelectSize} />
						: ''

				}
				{
					stateModal && <KkmStateModal id={Number(id)} onClose={() => setStateModal(false)} />
				}
				{
					confirmBlocKkm &&
					<ConfirmModal acceptButtonText='да' onAccept={block} onReject={() => setConfirmBlockKkm(false)}
												message={`Вы действительно хотите ${Kkm?.blockDate ? 'разблокировать' : 'заблокировать'} ККМ?`} />
				}
				{
					confirmBlockTraffic &&
					<ConfirmModal acceptButtonText='да' onAccept={blockTraffic}
												onReject={() => setBlockConfirmTraffic(false)}
												message={`Вы действительно хотите ${Kkm?.isTrafficBlocked ? 'разблокировать' : 'заблокировать'} трафик?`} />
				}
				{
					confirmChangeMaskValidation &&
					<ConfirmModal onAccept={setMaskValidation} acceptButtonText={'ОК'}
												onReject={() => setConfirmMaskValidation(false)}
												message={'Установить маску валидации?'} />
				}
				<Outlet />
				{
					KkmStore.loading && <Preload />
				}
				<ToastContainer />
			</>
		);
	},
);
